<template>
  <div class="app-container personal-basic-component">
    <el-tabs v-model="activeName">
      <!-- 个人资料 -->
      <el-tab-pane :label="$t('personal.setting2')" name="setting2">
        <personalData
          v-if="activeName === 'setting2'"
          @onUpload="uploadAvatar"
          @getUserInfo="getUserInfo"
          :currentUser="user"
        />
      </el-tab-pane>
      <!-- 修改手机 -->
      <el-tab-pane :label="$t('personal.setting4')" name="setting4">
        <UserPhone v-if="activeName === 'setting4'" :currentUser="user" />
      </el-tab-pane>
      <!-- 修改密码 -->
      <el-tab-pane :label="$t('personal.setting3')" name="setting3">
        <UserPassword />
      </el-tab-pane>
      <!-- 更换Logo -->
      <!-- v-rugePermission 不能直接放到el-tab-pane上，无法remove该节点，所以调用方法checkLogoPermission -->
      <el-tab-pane
        v-if="checkLogoPermission()"
        :label="$t('personal.setting5')"
        name="setting5"
      >
        <LogoChange />
      </el-tab-pane>
      <!-- 我的租户 -->
      <el-tab-pane :label="$t('personal.setting6')" name="setting6">
        <TenantProfile
          :haveAdminTenant="haveAdminTenant"
          :adminTenantName="adminTenantName"
        />
      </el-tab-pane>
      <!-- 权限申请 -->
      <el-tab-pane
        v-if="
          (belongTenant && !haveAdminTenant) ||
          (haveAdminTenant && !adminTenantName)
        "
        :label="$t('personal.setting11')"
        name="setting11"
      >
        <PermissionApply />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
let manager = require("@/api/ruge/sso/personal/personal.js");
import { getAdminTenant } from "@/api/ruge/tenant/addUser";
import UserPhone from "@/views/ruge/sso/personal/userPhone.vue";
import PersonalData from "@/views/ruge/sso/personal/personalData.vue";
import UserPassword from "@/views/ruge/sso/personal/userPassword.vue";
import TenantProfile from "@/views/ruge/sso/personal/tenantProfile.vue";
import PermissionApply from "@/views/ruge/grant/flow/permissionApply.vue";
import LogoChange from "@/views/ruge/sso/personal/logoChange.vue";
export default {
  name: "personalBasicComponent",
  components: {
    UserPhone,
    PersonalData,
    UserPassword,
    TenantProfile,
    PermissionApply,
    LogoChange,
  },
  data() {
    return {
      activeName: "setting2",
      user: {
        avatar: null,
        userAccount: null,
        userName: null,
        phone: null,
        email: null,
      },
      //自己的租户
      haveAdminTenant: false,
      //租户
      belongTenant: false,
      //自己的租户名
      adminTenantName: "",
    };
  },
  created() {
    this.getUserInfo();
    this.initAdminTenant();
  },
  methods: {
    uploadAvatar(image) {
      if (image) {
        manager
          .updateAvatar({
            avatarData: encodeURIComponent(image),
            userAccount: this.user.userAccount,
            avatar: this.user.avatar,
          })
          .then((r) => {
            if (r && r.avatar) {
              this.$message({
                type: "success",
                message: this.$t("message.operationSuccess"),
              });
              this.$store.dispatch("ChangeAvator", r.avatar);
            } else {
              this.$message({
                type: "error",
                message: this.$t("message.uploadFial"),
              });
            }
          });
      }
    },
    getUserInfo() {
      manager
        .findProfile({ userAccount: this.$store.getters.userAccount })
        .then((r) => {
          console.log("xxx-r", r);
          this.user.userAccount = r.userAccount;
          this.user.userName = r.userName;
          this.user.phone = r.phone;
          this.user.email = r.email;
          this.user.avatar = r.avatar;
          // 修改头像页面，不展示现在的头像
          // this.avatarUrl = envInfo.bgApp.ssoPath+"/param/avatar?p="+r.avatar
        });
    },
    checkLogoPermission() {
      return this.$rugePermission(
        "itenant$Service$tenantUserSetting$tenantSetting"
      );
    },
    initAdminTenant() {
      let currentTenant = this.$store.getters.currentTenant;
      currentTenant && (this.belongTenant = true);
      getAdminTenant().then((r) => {
        this.haveAdminTenant = r && r.length > 0;
        if (this.haveAdminTenant) {
          let tenantList = this.$store.getters.tenantList;
          let adminTenant = r[0];
          for (let j = 0; j < r.length; j++) {
            if (r[j] == currentTenant) {
              adminTenant = currentTenant;
            }
          }
          if (tenantList && adminTenant == currentTenant) {
            //当前在自己的租户下
            for (let i = 0; i < tenantList.length; i++) {
              if (tenantList[i].tenantId == adminTenant) {
                this.adminTenantName = tenantList[i].tenantName;
                return;
              }
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.personal-basic-component {
}
</style>