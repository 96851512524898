var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-password-comp" },
    [
      _c(
        "el-form",
        {
          ref: "pwdForm",
          attrs: {
            "label-position": "left",
            model: _vm.user,
            rules: _vm.pwdRule,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("userManager.oldPassword"),
                prop: "oldPassword",
              },
            },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: {
                  type: "password",
                  maxlength: "50",
                  "show-password": "",
                  placeholder: _vm.$t("userManager.passwordRequired"),
                },
                model: {
                  value: _vm.user.oldPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "oldPassword", $$v)
                  },
                  expression: "user.oldPassword",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("userManager.newPassword"),
                prop: "newPassword",
              },
            },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: {
                  type: "password",
                  maxlength: "50",
                  "show-word-limit": true,
                  "show-password": "",
                  placeholder: _vm.$t("userManager.newPasswordRequired"),
                },
                model: {
                  value: _vm.user.newPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "newPassword", $$v)
                  },
                  expression: "user.newPassword",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("userManager.confirmPassword"),
                prop: "confirmPassword",
              },
            },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: {
                  type: "password",
                  maxlength: "50",
                  "show-word-limit": true,
                  "show-password": "",
                  placeholder: _vm.$t("userManager.passwordReconfirm"),
                },
                model: {
                  value: _vm.user.confirmPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "confirmPassword", $$v)
                  },
                  expression: "user.confirmPassword",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.updatePwd } },
                [_vm._v(_vm._s(_vm.$t("commons.save")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }