var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "裁剪头像",
        visible: _vm.dialogVisible,
        "show-close": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "620px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { staticClass: "avatar-container" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.options.img,
                expression: "!options.img",
              },
            ],
          },
          [
            _c(
              "el-upload",
              {
                ref: "upload",
                staticClass: "upload",
                attrs: {
                  action: "",
                  "on-change": _vm.upload,
                  accept: "image/png, image/jpeg, image/jpg",
                  "show-file-list": false,
                  "auto-upload": false,
                },
              },
              [
                _c(
                  "el-button",
                  {
                    ref: "uploadBtn",
                    attrs: { slot: "trigger", size: "small", type: "primary" },
                    slot: "trigger",
                  },
                  [_vm._v("\n          选择图片\n        ")]
                ),
              ],
              1
            ),
            _c("div", [_vm._v("支持jpg、png格式的图片，大小不超过5M")]),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.options.img,
                expression: "options.img",
              },
            ],
            staticClass: "avatar-crop",
          },
          [
            _vm.dialogVisible
              ? _c("vueCropper", {
                  ref: "cropper",
                  staticClass: "crop-box",
                  attrs: {
                    outputType: "png",
                    img: _vm.options.img,
                    autoCrop: _vm.options.autoCrop,
                    fixedBox: _vm.options.fixedBox,
                    canMoveBox: _vm.options.canMoveBox,
                    autoCropWidth: _vm.options.autoCropWidth,
                    autoCropHeight: _vm.options.autoCropHeight,
                    centerBox: _vm.options.centerBox,
                    fixed: _vm.options.fixed,
                    fixedNumber: _vm.options.fixedNumber,
                    canMove: _vm.options.canMove,
                    canScale: _vm.options.canScale,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("div", { staticClass: "reupload", on: { click: _vm.reupload } }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.options.img,
                    expression: "options.img",
                  },
                ],
              },
              [_vm._v("重新上传")]
            ),
          ]),
          _c(
            "div",
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.getCrop } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }