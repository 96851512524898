
import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/**
 * 获取手机验证码
 */
export function getPhoneCode(params) {
    return request({
        url: envInfo.bgApp.ssoPath + '/sso/user/sendRegistCaptcha',
        method: 'post',
        data: params
    })
}

/**
 * 注册账号
 */
export function registUser(params) {
    return request({
        url: envInfo.bgApp.ssoPath + '/sso/user/registerSsoUser',
        method: 'post',
        data: params
    })
}

/**
 * 获取当前用户自己注册的租户
 * @param {*} params 
 * @return 租户ID
 */
export function getAdminTenant() {
    return request({
        url: envInfo.bgApp.ssoPath + '/sso/user/findAdminTenant',
        method: 'get'
    })
}

/**
 * 升级为租户
 * @param {Object} params 
 */
export function upgradToTenant(params) {
    return request({
        url: envInfo.bgApp.ssoPath + '/sso/user/upgradTenant',
        method: 'post',
        data: params
    })
}

