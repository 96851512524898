<template>
  <div class="user-password-comp">
    <el-form
      label-position="left"
      :model="user"
      ref="pwdForm"
      :rules="pwdRule"
      label-width="100px"
    >
      <el-form-item :label="$t('userManager.oldPassword')" prop="oldPassword">
        <el-input
          type="password"
          v-model="user.oldPassword"
          maxlength="50"
          style="width: 300px"
          show-password
          :placeholder="$t('userManager.passwordRequired')"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('userManager.newPassword')" prop="newPassword">
        <el-input
          type="password"
          v-model="user.newPassword"
          maxlength="50"
          :show-word-limit="true"
          style="width: 300px"
          show-password
          :placeholder="$t('userManager.newPasswordRequired')"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('userManager.confirmPassword')"
        prop="confirmPassword"
      >
        <el-input
          type="password"
          v-model="user.confirmPassword"
          maxlength="50"
          :show-word-limit="true"
          style="width: 300px"
          show-password
          :placeholder="$t('userManager.passwordReconfirm')"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="updatePwd">{{
          $t("commons.save")
        }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
let manager = require("@/api/ruge/sso/personal/personal.js");
import { encrypt2RSA } from "@/utils/encrypt";

export default {
  data() {
    return {
      user: {
        userAccount: this.$store.getters.userAccount,
        oldPassword: null,
        newPassword: null,
        confirmPassword: null,
      },
      pwdRule: {
        oldPassword: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        newPassword: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
          {
            validator: (rule, value, callback) => {
              if (
                this.user.confirmPassword &&
                value != this.user.confirmPassword
              ) {
                callback(new Error(this.$t("forget.validMessage.unMatchPwd")));
              }
              callback();
            },
            trigger: ["blur", "change"],
          },
        ],
        confirmPassword: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
          {
            validator: (rule, value, callback) => {
              if (this.user.newPassword && value != this.user.newPassword) {
                callback(new Error(this.$t("forget.validMessage.unMatchPwd")));
              }
              callback();
            },
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    updatePwd() {
      this.$refs.pwdForm.validate((v) => {
        if (v) {
          manager
            .updatePwd({
              userAccount: this.user.userAccount,
              oldPassword: encrypt2RSA(this.user.oldPassword),
              newPassword: encrypt2RSA(this.user.newPassword),
            })
            .then((r) => {
              if (r === 1) {
                this.$message({
                  type: "success",
                  message: this.$t("message.updateSuccess"),
                });
              } else if (r === -1) {
                this.$message({
                  type: "error",
                  message: this.$t("forget.validMessage.unMatchPwd"),
                });
              }
            });
        }
      });
    },
  },
};
</script>
<style>
.user-password-comp {
  padding-top: 40px;
}
.user-password-comp .el-form-item {
  margin-bottom: 40px;
}
.user-password-comp .el-form-item__label {
  line-height: 36px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #606266;
}
</style>