var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "logo-change-comp" }, [
    _c("div", { staticClass: "title-line" }, [
      _vm._v(_vm._s(_vm.$t("personal.logoChangeTitle1"))),
    ]),
    _c("div", { staticClass: "fold-image-contaainer" }, [
      _c("img", {
        staticClass: "small-image",
        attrs: {
          src: _vm.smallImageSrc,
          onerror: _vm.defaultSmallAvatar,
          alt: "",
        },
      }),
    ]),
    _c(
      "div",
      { staticClass: "buttons-container" },
      [
        _c(
          "el-upload",
          {
            attrs: {
              action: "#",
              "show-file-list": false,
              "http-request": _vm.smallImageUploadHandler,
            },
          },
          [
            _c("el-button", { attrs: { plain: "", size: "mini" } }, [
              _vm._v(_vm._s(_vm.$t("commons.upload"))),
            ]),
          ],
          1
        ),
        _c(
          "el-button",
          {
            staticClass: "margin-button",
            attrs: { type: "primary", size: "mini", plain: "" },
            on: {
              click: function ($event) {
                return _vm.uploadHandler("small")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("commons.save")))]
        ),
        _c(
          "el-button",
          {
            staticClass: "margin-button",
            attrs: { type: "danger", plain: "", size: "mini" },
            on: {
              click: function ($event) {
                return _vm.deleteHandler("small")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("commons.delete")))]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "title-line" }, [
      _vm._v(_vm._s(_vm.$t("personal.logoChangeTitle2"))),
    ]),
    _c("div", { staticClass: "open-image-container" }, [
      _c("img", {
        staticClass: "big-image",
        attrs: { src: _vm.bigImageSrc, onerror: _vm.defaultBigAvatar, alt: "" },
      }),
    ]),
    _c(
      "div",
      { staticClass: "buttons-container" },
      [
        _c(
          "el-upload",
          {
            attrs: {
              action: "#",
              "show-file-list": false,
              "http-request": _vm.bigImageUploadHandler,
            },
          },
          [
            _c("el-button", { attrs: { plain: "", size: "mini" } }, [
              _vm._v(_vm._s(_vm.$t("commons.upload"))),
            ]),
          ],
          1
        ),
        _c(
          "el-button",
          {
            staticClass: "margin-button",
            attrs: { type: "primary", size: "mini", plain: "" },
            on: {
              click: function ($event) {
                return _vm.uploadHandler("big")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("commons.save")))]
        ),
        _c(
          "el-button",
          {
            staticClass: "margin-button",
            attrs: { type: "danger", plain: "", size: "mini" },
            on: {
              click: function ($event) {
                return _vm.deleteHandler("big")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("commons.delete")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }