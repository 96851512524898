import { render, staticRenderFns } from "./logoChange.vue?vue&type=template&id=4d25fc54&scoped=true"
import script from "./logoChange.vue?vue&type=script&lang=js"
export * from "./logoChange.vue?vue&type=script&lang=js"
import style0 from "./logoChange.vue?vue&type=style&index=0&id=4d25fc54&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d25fc54",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\ruge-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4d25fc54')) {
      api.createRecord('4d25fc54', component.options)
    } else {
      api.reload('4d25fc54', component.options)
    }
    module.hot.accept("./logoChange.vue?vue&type=template&id=4d25fc54&scoped=true", function () {
      api.rerender('4d25fc54', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ruge/sso/personal/logoChange.vue"
export default component.exports