<template>
  <div class="permission-apply-comp" v-loading="loading">
    <!-- <div>权限申请建设中...</div> -->
    <el-row>
      <template v-if="roles.length > 0">
        <el-col
          class="ruge-cards"
          :span="5"
          v-for="(role, index) in roles"
          :key="role.roleId"
          :offset="index % 4 == 0 ? 0 : 1"
        >
          <el-card
            :class="role.choice"
            style="height: 180px"
            @click.native="choiceCard(role)"
          >
            <div :style="elCardIcon" slot="header">
              <span>{{ role.roleName }}</span>
            </div>
            <div>
              <span class="ruge-card-text">{{ role.roleNameDesc }}</span>
              <div class="bottom" @click.stop>
                <el-date-picker
                  style="width: 200px"
                  @change="momentDate(role)"
                  v-model="role.endDate"
                  type="date"
                  :placeholder="$t('commons.endDate')"
                >
                </el-date-picker>
              </div>
            </div>
            <img class="elCardActive" :src="elCardActiveSrc" />
          </el-card>
        </el-col>
      </template>
      <template v-else>
        <div class="no-data">{{ $t("commons.noData") }}</div>
      </template>
    </el-row>
    <div v-if="roles.length > 0">
      <el-button
        class="ruge-singleBtn-s"
        type="primary"
        size="mini"
        @click="applyPermission()"
      >
        {{ $t("commons.submit") }}
      </el-button>
    </div>
  </div>
</template>

<style rel="stylesheet/scss" lang="scss" scoped>
.bottom {
  margin-top: 20px;
  line-height: 12px;
}
</style>

<script>
let manager = require("@/api/ruge/security/role");
import moment from "moment";

export default {
  data() {
    return {
      loading: true,
      roles: [],
      elCardIcon: {
        background:
          "url(" +
          require("@/assets/images/el-card-icon.png") +
          ") center right no-repeat",
      },
      elCardActiveSrc: require("@/assets/images/el-card-active.png"),
    };
  },
  created() {
    this.initRoleCard();
  },
  props: {
    applyId: {
      require: false,
    },
    flowId: {
      require: false,
    },
  },
  methods: {
    initRoleCard() {
      this.loading = true;
      if (this.applyId) {
        manager
          .roleApproveDetail({ applyId: this.applyId })
          .then((detail) => {
            this.tenantRole(detail.roleApplyItemList);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        manager
          ._tenant_getRoleListByTenant({
            tenantId: this.$store.getters.currentTenant,
            userAccount: this.$store.getters.userAccount,
          })
          .then((roles) => {
            this.tenantRole(roles);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    tenantRole(haveRoles) {
      let hasRole = new Map();
      if (haveRoles) {
        haveRoles.map((m) => {
          hasRole.set(m.roleId, m);
        });
      }
      manager
        ._tenant_getRoleList({ tenantId: this.$store.currentTenant })
        .then((resp) => {
          let currentDate = new Date();
          this.roles = resp
            .filter((r) => r.roleId > 0)
            .map((r) => {
              r.roleNameDesc = r.roleNameDesc
                ? r.roleNameDesc.length > 15
                  ? r.roleNameDesc.substring(0, 15) + "..."
                  : r.roleNameDesc
                : r.roleName;
              if (r.roleId == -2) {
                r.disabled = true;
              } else {
                r.disabled = false;
              }
              let cr = hasRole.get(r.roleId);
              if (cr) {
                //r.startDate = cr.startDate;
                r.endDate = moment(cr.endDate).toDate().getTime();
                r.choice = "choice";
              } else {
                r.choice = "";
                //r.startDate = currentDate;
                r.endDate = new Date().setFullYear(
                  currentDate.getFullYear() + 1
                );
              }
              //r.dateRange = [r.startDate, r.endDate];
              return r;
            });
        })
        .catch(() => {});
    },
    choiceCard(role) {
      if (role.roleId == -2) {
        return;
      }
      if (role.choice) {
        this.$set(role, "choice", "");
      } else {
        this.$set(role, "choice", "choice");
      }
    },
    momentDate(role) {
      role.endDate = moment(role.endDate).toDate().getTime();
    },
    applyPermission() {
      let temp = this.roles.filter((m) => {
        return m.choice;
      });
      let roles = new Array();
      temp.map((m) => {
        if (!m.endDate) {
          this.$message({
            type: "error",
            message: this.$t("permission.validMessage.dateNull"),
          });
          roles = new Array();
          return;
        }
        roles.push({ roleId: m.roleId, endDate: m.endDate });
      });
      if (roles.length > 0) {
        if (this.applyId) {
          this.reSubmit(roles);
        } else {
          this.applySubmit(roles);
        }
      }
    },
    applySubmit(roles) {
      let applyForm = {
        flowKey: "applyPermission",
        roleApplyItemList: roles,
      };
      manager
        .applyPermission(applyForm)
        .then(() => {
          this.$message({
            type: "success",
            message: this.$t("message.submitSuccess"),
          });
        })
        .catch((e) => {
          console.log(`提交失败，原因 => ${e}`);
        });
    },
    reSubmit(roles) {
      let reSubmitForm = {
        applyId: this.applyId,
        flowId: this.flowId,
        roleApplyItemList: roles,
      };
      manager
        .reApplyPermission(reSubmitForm)
        .then(() => {
          this.$message({
            type: "success",
            message: this.$t("message.reSubmitSuccess"),
          });
          this.$router.push("/workflow/my/todo");
        })
        .catch((e) => {
          console.log(`提交失败，原因 => ${e}`);
        });
    },
  },
};
</script>
<style scoped lang="less">
.permission-apply-comp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
</style>