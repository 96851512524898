<template>
  <div class="logo-change-comp">
    <div class="title-line">{{ $t("personal.logoChangeTitle1") }}</div>
    <div class="fold-image-contaainer">
      <img
        :src="smallImageSrc"
        :onerror="defaultSmallAvatar"
        class="small-image"
        alt=""
      />
    </div>
    <div class="buttons-container">
      <el-upload
        action="#"
        :show-file-list="false"
        :http-request="smallImageUploadHandler"
      >
        <el-button plain size="mini">{{ $t("commons.upload") }}</el-button>
      </el-upload>
      <el-button
        type="primary"
        @click="uploadHandler('small')"
        size="mini"
        plain
        class="margin-button"
        >{{ $t("commons.save") }}</el-button
      >
      <el-button
        type="danger"
        class="margin-button"
        plain
        size="mini"
        @click="deleteHandler('small')"
        >{{ $t("commons.delete") }}</el-button
      >
    </div>
    <div class="title-line">{{ $t("personal.logoChangeTitle2") }}</div>
    <div class="open-image-container">
      <img
        :src="bigImageSrc"
        :onerror="defaultBigAvatar"
        class="big-image"
        alt=""
      />
    </div>
    <div class="buttons-container">
      <el-upload
        action="#"
        :show-file-list="false"
        :http-request="bigImageUploadHandler"
      >
        <el-button plain size="mini">{{ $t("commons.upload") }}</el-button>
      </el-upload>
      <el-button
        type="primary"
        @click="uploadHandler('big')"
        size="mini"
        plain
        class="margin-button"
        >{{ $t("commons.save") }}</el-button
      >
      <el-button
        type="danger"
        class="margin-button"
        plain
        size="mini"
        @click="deleteHandler('big')"
        >{{ $t("commons.delete") }}</el-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { envInfo } from "@/constants/envInfo";
let tenant = require("@/api/ruge/tenant/tenant.js");
export default {
  data() {
    return {
      smallImageSrc: null,
      bigImageSrc: null,
      curTenantList: {},
      defaultSmallAvatar:
        'this.src="' + require("@/assets/images/defaultSmallAvatar.png") + '"',
      defaultBigAvatar:
        'this.src="' + require("@/assets/images/defaultBigAvatar.png") + '"',
    };
  },
  watch: {
    currentTenant: {
      handler(tenant) {
        this.freshImageSrcs(tenant);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["currentTenant", "tenantList"]),
  },
  methods: {
    freshImageSrcs(tenant) {
      let curTenantList = this.tenantList.filter(
        (item) => item.tenantId == tenant
      )[0];
      this.smallImageSrc =
        envInfo.bgApp.tenantPath +
        "/param/tenant/avatar?p=" +
        curTenantList.tenantAvatar;
      this.bigImageSrc =
        envInfo.bgApp.tenantPath +
        "/param/tenant/avatar?p=" +
        curTenantList.tenantAvatarOpen;
      this.curTenantList = curTenantList;
    },
    smallImageUploadHandler(data) {
      this.getBase64(data.file).then((resBase64) => {
        this.smallImageSrc = resBase64;
      });
    },
    bigImageUploadHandler(data) {
      this.getBase64(data.file).then((resBase64) => {
        this.bigImageSrc = resBase64;
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        let fileResult = "";
        reader.readAsDataURL(file);
        // 开始转换
        reader.onload = () => {
          fileResult = reader.result;
        };
        // 转失败
        reader.onerror = (error) => {
          reject(error);
        };
        // 转结束
        reader.onloadend = () => {
          resolve(fileResult);
        };
      });
    },
    base64Format(datas) {
      datas = datas
        .replace(/^data:image\/jpeg;base64,/, "")
        .replace(/^data:image\/jpg;base64,/, "")
        .replace(/^data:image\/png;base64,/, "")
        .replace(/^data:image\/svg\+xml;base64,/, "");
      return datas;
    },
    uploadHandler(type) {
      let params = {};
      if (type === "small") {
        params = {
          avatarData: encodeURIComponent(this.base64Format(this.smallImageSrc)),
          tenantAvatar: this.$store.getters.constomLogo,
        };
      } else {
        params = {
          avatarOpenData: encodeURIComponent(
            this.base64Format(this.bigImageSrc)
          ),
          tenantAvatarOpen: this.$store.getters.constomLogo,
        };
      }
      this.uploadTenantAvatar(params, type);
    },
    deleteHandler(type) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          this.deleteTenantAvatar(type);
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    deleteTenantAvatar(type) {
      let params = {};
      if (type === "small") {
        params = { tenantAvatar: this.curTenantList.tenantAvatar };
      } else {
        params = { tenantAvatarOpen: this.curTenantList.tenantAvatarOpen };
      }
      tenant.deleteTenantAvatar(params).then((res) => {
        if (res) {
          this.$message.success("删除成功！");
          this.updateTenantList();
        }
      });
    },
    uploadTenantAvatar(params, type) {
      tenant.updateTenantAvatar(params).then((r) => {
        if (r && (r.tenantAvatar || r.tenantAvatarOpen)) {
          this.$message({
            type: "success",
            message: this.$t("message.operationSuccess"),
          });
          if (type === "small") {
            this.$store.dispatch("ChangeTenantAvator", r.tenantAvatar);
          } else {
            this.$store.dispatch("ChangeTenantAvatorOpen", r.tenantAvatarOpen);
          }
          // 当前logo更新完后，需要刷新tenantList，不然可能马上点菜单展开/收缩会展示旧的logo
          this.updateTenantList();
        } else {
          this.$message({
            type: "error",
            message: this.$t("message.uploadFial"),
          });
        }
      });
    },
    // 更新tenantList
    updateTenantList() {
      this.$store.dispatch("InitTenantList").then((tenantList) => {
        if (this.$store.getters.currentTenant) {
          this.$store
            .dispatch("SetCurrentTenant", {
              tenantId: this.$store.getters.currentTenant,
              productKey: this.$store.getters.currentProductKey,
            })
            .then(() => {});
          let tenantAvatar = tenantList.filter(
            (t) => t.tenantId == this.$store.getters.currentTenant
          )[0].tenantAvatar;
          this.$store.dispatch("ChangeTenantAvator", tenantAvatar).then();
          this.freshImageSrcs(this.currentTenant);
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.logo-change-comp {
  padding-top: 40px;
  .title-line {
    margin-bottom: 16px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #303133;
  }
  .buttons-container {
    display: flex;
    margin-bottom: 40px;
    .margin-button {
      margin-left: 12px;
    }
  }
  .fold-image-contaainer {
    height: 56px;
    width: 64px;
    background-color: #2a4158;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    .small-image {
      max-height: 32px;
      max-width: 32px;
    }
  }
  .open-image-container {
    height: 56px;
    width: 180px;
    background-color: #2a4158;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    .big-image {
      max-height: 32px;
      max-width: 140px;
      margin: auto 20px;
    }
  }
}
</style>