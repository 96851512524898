<template>
  <div class="user-phone-comp">
    <el-form
      label-position="left"
      :model="user"
      ref="phoneForm"
      :rules="phoneRule"
      label-width="100px"
    >
      <el-form-item :label="$t('userManager.phone')" prop="phone">
        <el-select
          v-model="user.zoneCode"
          :placeholder="$t('userManager.zoneCode')"
          class="zonecode-input"
        >
          <el-option
            :key="item.itemCode"
            :value="item.itemCode"
            :label="item.itemName"
            v-for="item in zoneCodeList"
          ></el-option>
        </el-select>
        <el-input
          v-model="user.phone"
          maxlength="11"
          @focus="loadImgCaptcha"
          class="phone-input"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('userManager.captcha')"
        prop="captcha"
        v-if="onPhone && !onAuthCaptcha"
      >
        <el-input
          v-model="user.captcha"
          maxlength="10"
          class="vcode-input-width"
          clearable
        ></el-input>
        <img :src="vCode" class="vcode-img-big" />
        <el-button type="text" class="vcode-img-change" @click="changeVCode">{{
          $t("commons.inA")
        }}</el-button>
      </el-form-item>
      <el-form-item v-if="onPhone && !onAuthCaptcha">
        <el-button type="primary" @click="sendUptCaptcha">{{
          $t("commons.send")
        }}</el-button>
      </el-form-item>
    </el-form>
    <el-form
      label-position="top"
      :model="user"
      ref="captchaForm"
      :rules="phoneRule"
      label-width="100px"
      v-if="onAuthCaptcha"
    >
      <el-form-item
        :label="$t('userManager.captcha')"
        prop="authCaptcha"
        ref="“authCaptcha”"
      >
        <el-input
          v-model="user.authCaptcha"
          maxlength="10"
          :placeholder="$t('forget.entryCaptcha')"
          style="width: 370px"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="updatePhone">{{
          $t("commons.confirm")
        }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { validCNPhone, validateCaptcha } from "@/utils/validate";
let manager = require("@/api/ruge/sso/personal/personal.js");
import { envInfo } from "@/constants/envInfo";

export default {
  data() {
    return {
      user: {
        userAccount: this.$store.getters.userAccount,
        zoneCode: "86",
        phone: null,
        captcha: null,
        authCaptcha: null,
      },
      zoneCodeList: null,
      vCode: null,
      onPhone: false,
      onAuthCaptcha: false,
      phoneRule: {
        phone: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
          {
            validator: (rule, value, callback) => {
              if (value && !validCNPhone(value)) {
                callback(
                  new Error(this.$t("userManager.validMessage.phoneRegx"))
                );
              }
              callback();
            },
            trigger: ["blur", "change"],
          },
        ],
        zoneCode: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        captcha: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        authCaptcha: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
          {
            validator: (rule, value, callback) => {
              if (!validateCaptcha(value, 6)) {
                callback(new Error(this.$t("message.invalidCaptcha")));
              }
              callback();
            },
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  props: {
    currentUser: {
      type: Object,
    },
  },
  watch: {
    currentUser: {
      handler(val) {
        this.user.phone = val.phone;
        manager
          .findPhoneZoneCode({ classifyCode: "PHONE_ZONE_CODE" })
          .then((r) => {
            this.zoneCodeList = r;
          });
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  methods: {
    changeVCode() {
      manager.getCaptchaPicture().then((res) => {
        if (res.loginLock === "1") {
          this.$message.warning(`请${res.leftLockExpireTime}秒后再试!`);
          return;
        }
        this.vCode = res.image;
      });
    },
    loadImgCaptcha() {
      if (this.onPhone) {
        return;
      }
      this.onPhone = true;
      this.changeVCode();
    },
    sendUptCaptcha() {
      this.$refs.phoneForm.validate((v) => {
        if (v) {
          manager
            .sendUptCaptcha({
              zoneCode: this.user.zoneCode,
              phone: this.user.phone,
              captcha: this.user.captcha,
            })
            .then((r) => {
              if (r == 1) {
                this.onPhone = false;
                this.onAuthCaptcha = true;
                this.$message({
                  type: "success",
                  message: this.$t("message.updateSuccess"),
                });
              } else if (r == -1) {
                // 验证码错误
                this.$message({
                  type: "error",
                  message: this.$t("userManager.message.captcha_1"),
                });
              } else if (r == 5) {
                // 手机号已被注册
                this.$message({
                  type: "error",
                  message: this.$t("userManager.message.existPhone"),
                });
              } else if (r == -3) {
                // 请刷新验证码
                this.$message({
                  type: "error",
                  message: this.$t("userManager.message.captcha_2"),
                });
              }
            });
        }
      });
    },
    updatePhone() {
      this.$refs.captchaForm.validate((v) => {
        if (v) {
          manager.updatePhone(this.user).then((r) => {
            if (r == 1) {
              this.$message({
                type: "success",
                message: this.$t("message.updateSuccess"),
              });
              this.onAuthCaptcha = false;
              this.user.captcha = null;
              this.user.authCaptcha = null;
            } else if (r == 5) {
              this.$message({
                type: "error",
                message: this.$t("userManager.message.existPhone"),
              });
            } else if (r == 6) {
              // 验证码错误
              this.$message({
                type: "error",
                message: this.$t("userManager.message.captcha_1"),
              });
            } else {
              this.$message({
                type: "error",
                message: this.$t("message.updateFail"),
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.zonecode-input {
  float: left;
  width: 140px;
  margin-right: 0px;
}

.phone-input {
  float: left;
  width: 220px;
  margin-left: 10px;
}
.vcode-input-width {
  float: left;
  width: 140px;
  margin-right: 0px;
}

.vcode-img-big {
  float: left;
  width: 100px;
  height: 38px;
  border: 1px solid #ddd;
  margin-left: 10px;
}

.vcode-img-change {
  padding-left: 10px;
  padding-top: 13px;
  display: block;
  padding-top: 9px;
  float: left;
}
</style>
<style>
.user-phone-comp {
  padding-top: 40px;
}
.user-phone-comp .el-form-item {
  margin-bottom: 40px;
}
.user-phone-comp .el-form-item__label {
  line-height: 36px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #606266;
}
</style>