<template>
  <div class="tenant-profile-comp">
    <el-form
      label-position="left"
      :model="tenant"
      ref="tenantForm"
      :rules="tenantFormRule"
      label-width="100px"
    >
      <template v-if="haveAdminTenant && !adminTenantName">
        <el-row style="margin-bottom: 30px">{{
          $t("tenant.message.haveTenant")
        }}</el-row>
      </template>
      <template v-else-if="!haveAdminTenant">
        <el-row style="margin-bottom: 30px">
          {{ $t("tenant.message.notHaveP1") }}
          <el-link type="primary" @click="onUpgrad">{{
            $t("tenant.message.upgrade")
          }}</el-link>
          {{ $t("tenant.message.notHaveP2") }}
        </el-row>
      </template>

      <template v-if="productKey">
        <el-form-item :label="$t('tenant.productKey')" prop="productKey">
          <label>{{ productKey }}</label>
        </el-form-item>
      </template>
      <template v-if="upgrad || adminTenantName">
        <el-form-item :label="$t('tenant.tenantName')" prop="tenantName">
          <el-input
            v-model="tenant.tenantName"
            maxlength="80"
            :show-word-limit="true"
            style="width: 300px"
            clearable
            :placeholder="$t('login.companyHolder')"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <template v-if="haveAdminTenant">
            <el-button type="primary" @click="updateTenantProfile">{{
              $t("commons.save")
            }}</el-button>
          </template>
          <template v-else>
            <el-button type="primary" @click="upgradToTenant">{{
              $t("commons.save")
            }}</el-button>
          </template>
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>

<script>
let tenant = require("@/api/ruge/tenant/tenant.js");
import { upgradToTenant } from "@/api/ruge/tenant/addUser";
export default {
  data() {
    return {
      tenant: {
        tenantName: "",
      },
      productKey: "",
      upgrad: false,
      tenantFormRule: {
        tenantName: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
      },
    };
  },
  props: {
    haveAdminTenant: {
      require: true,
      default: false,
    },
    adminTenantName: {
      require: false,
      default: "",
    },
  },
  watch: {
    adminTenantName: {
      handler(val) {
        this.tenant.tenantName = val;
      },
      immediate: true,
    },
  },
  created() {
    this.productKey = this.$store.getters.currentProductKey;
  },
  methods: {
    onUpgrad() {
      this.upgrad = true;
    },
    upgradToTenant() {
      this.$refs.tenantForm.validate((v) => {
        if (v) {
          upgradToTenant({ tenantName: this.tenantForm.tenantName }).then(
            (r) => {
              if (r > 0) {
                this.$message({
                  type: "success",
                  message: this.$t("message.registerSuccess"),
                });
              }
            }
          );
        }
      });
    },
    updateTenantProfile() {
      this.$refs.tenantForm.validate((v) => {
        if (v) {
          tenant.updateTenantName(this.tenant).then((r) => {
            if (r > 0) {
              this.$message({
                type: "success",
                message: this.$t("message.updateSuccess"),
              });
            } else {
              this.$message({
                type: "error",
                message: this.$t("message.updateFail"),
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style>
.tenant-profile-comp {
  padding-top: 40px;
}
.tenant-profile-comp .el-form-item {
  margin-bottom: 40px;
}
.tenant-profile-comp .el-form-item__label {
  line-height: 36px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #606266;
}
</style>