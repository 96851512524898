var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tenant-profile-comp" },
    [
      _c(
        "el-form",
        {
          ref: "tenantForm",
          attrs: {
            "label-position": "left",
            model: _vm.tenant,
            rules: _vm.tenantFormRule,
            "label-width": "100px",
          },
        },
        [
          _vm.haveAdminTenant && !_vm.adminTenantName
            ? [
                _c("el-row", { staticStyle: { "margin-bottom": "30px" } }, [
                  _vm._v(_vm._s(_vm.$t("tenant.message.haveTenant"))),
                ]),
              ]
            : !_vm.haveAdminTenant
            ? [
                _c(
                  "el-row",
                  { staticStyle: { "margin-bottom": "30px" } },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("tenant.message.notHaveP1")) +
                        "\n        "
                    ),
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.onUpgrad },
                      },
                      [_vm._v(_vm._s(_vm.$t("tenant.message.upgrade")))]
                    ),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("tenant.message.notHaveP2")) +
                        "\n      "
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.productKey
            ? [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("tenant.productKey"),
                      prop: "productKey",
                    },
                  },
                  [_c("label", [_vm._v(_vm._s(_vm.productKey))])]
                ),
              ]
            : _vm._e(),
          _vm.upgrad || _vm.adminTenantName
            ? [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("tenant.tenantName"),
                      prop: "tenantName",
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "300px" },
                      attrs: {
                        maxlength: "80",
                        "show-word-limit": true,
                        clearable: "",
                        placeholder: _vm.$t("login.companyHolder"),
                      },
                      model: {
                        value: _vm.tenant.tenantName,
                        callback: function ($$v) {
                          _vm.$set(_vm.tenant, "tenantName", $$v)
                        },
                        expression: "tenant.tenantName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _vm.haveAdminTenant
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.updateTenantProfile },
                            },
                            [_vm._v(_vm._s(_vm.$t("commons.save")))]
                          ),
                        ]
                      : [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.upgradToTenant },
                            },
                            [_vm._v(_vm._s(_vm.$t("commons.save")))]
                          ),
                        ],
                  ],
                  2
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }