var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-phone-comp" },
    [
      _c(
        "el-form",
        {
          ref: "phoneForm",
          attrs: {
            "label-position": "left",
            model: _vm.user,
            rules: _vm.phoneRule,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("userManager.phone"), prop: "phone" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "zonecode-input",
                  attrs: { placeholder: _vm.$t("userManager.zoneCode") },
                  model: {
                    value: _vm.user.zoneCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.user, "zoneCode", $$v)
                    },
                    expression: "user.zoneCode",
                  },
                },
                _vm._l(_vm.zoneCodeList, function (item) {
                  return _c("el-option", {
                    key: item.itemCode,
                    attrs: { value: item.itemCode, label: item.itemName },
                  })
                }),
                1
              ),
              _c("el-input", {
                staticClass: "phone-input",
                attrs: { maxlength: "11", clearable: "" },
                on: { focus: _vm.loadImgCaptcha },
                model: {
                  value: _vm.user.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "phone", $$v)
                  },
                  expression: "user.phone",
                },
              }),
            ],
            1
          ),
          _vm.onPhone && !_vm.onAuthCaptcha
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("userManager.captcha"),
                    prop: "captcha",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "vcode-input-width",
                    attrs: { maxlength: "10", clearable: "" },
                    model: {
                      value: _vm.user.captcha,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "captcha", $$v)
                      },
                      expression: "user.captcha",
                    },
                  }),
                  _c("img", {
                    staticClass: "vcode-img-big",
                    attrs: { src: _vm.vCode },
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "vcode-img-change",
                      attrs: { type: "text" },
                      on: { click: _vm.changeVCode },
                    },
                    [_vm._v(_vm._s(_vm.$t("commons.inA")))]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.onPhone && !_vm.onAuthCaptcha
            ? _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.sendUptCaptcha },
                    },
                    [_vm._v(_vm._s(_vm.$t("commons.send")))]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.onAuthCaptcha
        ? _c(
            "el-form",
            {
              ref: "captchaForm",
              attrs: {
                "label-position": "top",
                model: _vm.user,
                rules: _vm.phoneRule,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  ref: "“authCaptcha”",
                  attrs: {
                    label: _vm.$t("userManager.captcha"),
                    prop: "authCaptcha",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "370px" },
                    attrs: {
                      maxlength: "10",
                      placeholder: _vm.$t("forget.entryCaptcha"),
                    },
                    model: {
                      value: _vm.user.authCaptcha,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "authCaptcha", $$v)
                      },
                      expression: "user.authCaptcha",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.updatePhone },
                    },
                    [_vm._v(_vm._s(_vm.$t("commons.confirm")))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }