<template>
  <div class="personal-data-comp">
    <div class="header-image-line">
      <!-- <span class="title-part"
        ><img
          :src="DefaultUserImage"
          alt=""
          style="height: 64px; width: 64px; border-radius: 50%"
      /></span> -->
      <span class="title-part">
        <input
          v-show="false"
          accept="image/jpg, image/jpeg, image/png"
          id="avatarInput"
          ref="avatarInput"
          @change="changeFile"
          type="file"
        />
        <span class="image-container">
          <img
            :onerror="defaultAvatar"
            :src="imgSrc || avatarP"
            ref="imageBox"
            class="user-avatar"
            alt=""
          />
          <!-- <img
            v-else
            :src="avatarP"
            :onerror="defaultAvatar"
            class="user-avatar"
          /> -->
        </span>
      </span>
      <div>
        <!-- <el-button plain @click="chooseFile">上传头像</el-button> -->
        <el-button plain @click="dialogVisible = true">上传头像</el-button>
        <el-button plain @click="setDefaultImage">使用默认头像</el-button>
      </div>
    </div>
    <div class="name-line">
      <span class="title-part">姓名</span>
      <div class="detail-part">
        <div v-if="!basicData.name.editState">
          <span>{{ basicData.name.label }}</span>
          <i class="el-icon-edit" @click="activeEdit('name')"></i>
        </div>
        <div v-else class="button-container">
          <el-input
            size="mini"
            ref="name-input"
            v-model="basicData.name.label"
            @keyup.enter.native="saveHandler('name')"
          ></el-input>
          <el-button
            icon="el-icon-check"
            size="mini"
            @click="saveHandler('name')"
          ></el-button>
          <el-button
            icon="el-icon-close"
            size="mini"
            @click="cancelHandler('name')"
          ></el-button>
        </div>
      </div>
    </div>
    <div class="email-line">
      <span class="title-part">E-mail</span>
      <div class="detail-part">
        <div v-if="!basicData.email.editState">
          <span>{{ basicData.email.label || "-" }}</span>
          <i class="el-icon-edit" @click="activeEdit('email')"></i>
        </div>
        <div v-else class="button-container">
          <el-input
            size="mini"
            ref="email-input"
            v-model="basicData.email.label"
            @keyup.enter.native="saveHandler('email')"
          ></el-input>
          <el-button
            icon="el-icon-check"
            size="mini"
            @click="saveHandler('email')"
          ></el-button>
          <el-button
            icon="el-icon-close"
            size="mini"
            @click="cancelHandler('email')"
          ></el-button>
        </div>
      </div>
    </div>
    <AvatarCropper
      :dialogVisible.sync="dialogVisible"
      @closeAvatarDialog="closeAvatarDialog"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { envInfo } from "@/constants/envInfo";
const DetaultBase64 = `data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4IiB2aWV3Qm94PSIwIDAgNjQgNjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+ZGVmYXVsdF91c2VyPC90aXRsZT4KICAgIDxnIGlkPSIxMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IuS4quS6uuS4reW/g+Wkh+S7vV/ln7rmnKzotYTmlplf5Liq5Lq65L+h5oGvIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjAyLjAwMDAwMCwgLTIyOC4wMDAwMDApIj4KICAgICAgICAgICAgPGcgaWQ9ImRlZmF1bHRfdXNlciIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjAyLjAwMDAwMCwgMjI4LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGNpcmNsZSBpZD0i5qSt5ZyG5b2iIiBmaWxsPSIjRTRFN0VEIiBjeD0iMzIiIGN5PSIzMiIgcj0iMzIiPjwvY2lyY2xlPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTMyLDMxLjU2NzU2NzYgQzM2LjQyLDMxLjU2NzU2NzYgNDAsMjguMDg0MzI0MyA0MCwyMy43ODM3ODM4IEM0MCwxOS40ODMyNDMyIDM2LjQyLDE2IDMyLDE2IEMyNy41OCwxNiAyNCwxOS40ODMyNDMyIDI0LDIzLjc4Mzc4MzggQzI0LDI4LjA4NDMyNDMgMjcuNTgsMzEuNTY3NTY3NiAzMiwzMS41Njc1Njc2IFogTTMyLDM1Ljg5MTg5MTkgQzI2LjY2LDM1Ljg5MTg5MTkgMTYsMzguNTk2MDM2IDE2LDQzLjk2Mzk2NCBMMTYsNDggTDQ4LDQ4IEw0OCw0My45NjM5NjQgQzQ4LDM4LjU5NjAzNjEgMzcuMzQsMzUuODkxODkxOSAzMiwzNS44OTE4OTE5IFoiIGlkPSLlvaLnirYiIGZpbGw9IiNGRkZGRkYiIGZpbGwtcnVsZT0ibm9uemVybyI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=`;
let manager = require("@/api/ruge/sso/personal/personal.js");
import AvatarCropper from "./avatarCropper.vue";
export default {
  components: {
    AvatarCropper,
  },
  props: {
    currentUser: {
      type: Object,
    },
  },
  watch: {
    currentUser: {
      handler(val) {
        this.basicData.name.label = val.userName;
        this.basicData.email.label = val.email;
        this.basicData.tele.label = val.phone;
        console.log("val", this.basicData);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["userAccount", "avatar"]),
    avatarP() {
      if (this.avatar) {
        return envInfo.bgApp.ssoPath + "/param/avatar?p=" + this.avatar;
      } else {
        return envInfo.bgApp.ssoPath + "/param/avatar?u=" + this.userAccount;
      }
    },
  },
  data() {
    return {
      dialogVisible: false,
      imgSrc: null,
      defaultAvatar:
        'this.src="' + require("@/assets/images/defaultAvatar.png") + '"',
      basicData: {
        name: {
          label: "",
          editState: false,
        },
        email: {
          label: "",
          editState: false,
        },
        tele: {
          label: "",
          editState: false,
        },
      },
    };
  },
  methods: {
    closeAvatarDialog(datas) {
      this.upload(datas);
    },
    setDefaultImage() {
      const defaultImage = decodeURIComponent(DetaultBase64);
      this.upload(defaultImage);
    },
    chooseFile() {
      this.$refs.avatarInput.click();
    },
    changeFile() {
      let reader = new FileReader();
      let vue = this;
      reader.onloadend = function (e) {
        vue.imgSrc = e.target.result;
        // vue.$nextTick(function() {
        //     if(vue.cropper) {
        //         vue.cropper.replace(vue.imgSrc)
        //     } else {
        //         vue.cropper = new Cropper(vue.$refs.imageBox, vue.cropOption)
        //     }
        //     // vue.zoomShow();
        // })
        vue.upload();
      };
      reader.readAsDataURL(this.$refs.avatarInput.files[0]);
      // this.zoomStyle = ""
    },
    upload(datas) {
      // if (this.cropper) {
      // let img = this.imgSrc;
      datas = datas
        .replace(/^data:image\/jpeg;base64,/, "")
        .replace(/^data:image\/jpg;base64,/, "")
        .replace(/^data:image\/png;base64,/, "")
        .replace(/^data:image\/svg\+xml;base64,/, "");
      this.$emit("onUpload", datas);
      // }
    },
    activeEdit(type) {
      this.basicData[type].editState = true;
      this.$nextTick(() => {
        this.$refs[type + "-input"].focus();
        this.$refs[type + "-input"].select();
      });
    },
    saveHandler(type) {
      this.requestHandler(type);
      this.basicData[type].editState = false;
    },
    cancelHandler(type) {
      this.basicData[type].editState = false;
      if (type === "name") {
        this.basicData.name.label = this.currentUser.userName;
      }
      if (type === "email") {
        this.basicData.email.label = this.currentUser.email;
      }
    },
    requestHandler(type) {
      // if (type === "name" || type === "email") {
      const params = {
        userAccount: this.$store.getters.userAccount,
        userName: this.basicData.name.label,
        email: this.basicData.email.label,
      };
      manager.updateProfile(params).then((r) => {
        if (r > 0) {
          this.$message({
            type: "success",
            message: this.$t("message.updateSuccess"),
          });
          this.$emit("getUserInfo");
        } else {
          this.$message({
            type: "error",
            message: this.$t("message.updateFail"),
          });
        }
      });
      // }
      //  else if (type === "tele") {
      //   manager.updatePhone(this.user).then(r=> {
      //       if(r == 1) {
      //           this.$message({type: 'success', message: this.$t('message.updateSuccess')});
      //           this.onAuthCaptcha = false
      //           this.user.captcha = null
      //           this.user.authCaptcha = null
      //       } else if(r == 5) {
      //           this.$message({type: 'error', message: this.$t('userManager.message.existPhone')});
      //       } else if (r == 6) {
      //           // 验证码错误
      //           this.$message({type: 'error', message: this.$t('userManager.message.captcha_1')});
      //       } else {
      //           this.$message({type: 'error', message: this.$t('message.updateFail')});
      //       }
      //   })
      // }
    },
  },
};
</script>

<style scoped lang="less">
.personal-data-comp {
  padding-top: 40px;
  & > div {
    height: 20px;
    line-height: 20px;
    margin-bottom: 40px;
    display: flex;
  }
  .header-image-line {
    height: 64px;
    display: flex;
    align-items: center;
  }
  .user-avatar {
    height: 64px;
    width: 64px;
    border-radius: 50%;
  }
  .title-part {
    display: inline-block;
    width: 120px;
    text-align: left;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #606266;
    .image-container {
      display: inline-block;
      height: 64px;
      width: 64px;
      border-radius: 50%;
    }
  }
  .detail-part {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #303133;
    display: flex;
    align-items: center;
    .button-container {
      display: flex;
      button {
        margin-left: 8px;
      }
    }
  }
  .el-icon-edit {
    cursor: pointer;
    margin-left: 8px;
    color: #c0c4cc;
    font-size: 14px;
  }
  .el-button + .el-button {
    margin-left: 12px;
  }
}
</style>
<style scoped>
.default-user-icon >>> svg {
  height: 64px;
  width: 64px;
  cursor: pointer;
  fill: #b4c5d5;
}
</style>