var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container personal-basic-component" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("personal.setting2"), name: "setting2" } },
            [
              _vm.activeName === "setting2"
                ? _c("personalData", {
                    attrs: { currentUser: _vm.user },
                    on: {
                      onUpload: _vm.uploadAvatar,
                      getUserInfo: _vm.getUserInfo,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("personal.setting4"), name: "setting4" } },
            [
              _vm.activeName === "setting4"
                ? _c("UserPhone", { attrs: { currentUser: _vm.user } })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("personal.setting3"), name: "setting3" } },
            [_c("UserPassword")],
            1
          ),
          _vm.checkLogoPermission()
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("personal.setting5"),
                    name: "setting5",
                  },
                },
                [_c("LogoChange")],
                1
              )
            : _vm._e(),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("personal.setting6"), name: "setting6" } },
            [
              _c("TenantProfile", {
                attrs: {
                  haveAdminTenant: _vm.haveAdminTenant,
                  adminTenantName: _vm.adminTenantName,
                },
              }),
            ],
            1
          ),
          (_vm.belongTenant && !_vm.haveAdminTenant) ||
          (_vm.haveAdminTenant && !_vm.adminTenantName)
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("personal.setting11"),
                    name: "setting11",
                  },
                },
                [_c("PermissionApply")],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }